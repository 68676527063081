var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: [
        "form-list-element-field",
        { "form-list-element-field--disabled": _vm.disabled }
      ]
    },
    [
      _c("IconDelete", {
        staticClass: "form-list-element-field__delete-field",
        attrs: { "fill-color": _vm.fillColor },
        nativeOn: {
          click: function($event) {
            return _vm.onRemoveField.apply(null, arguments)
          }
        }
      }),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }